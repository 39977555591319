.heropage_ul li {
  list-style: none;
  font-size: 20px;
  font-weight: 700;
  color: blue;
  margin-right: 40px;
}


.interview {
  width: 100%;
}

.row2 {
  box-shadow: rgba(228, 7, 7, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  margin-top: 40px;
  border-radius: 10px;
  margin: 40px 0.5px 0px 0.5px;
}

.container {
  width: 100%;
}
