.icon-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
  
  .icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon-box img {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }
  
  .icon-box span {
    font-size: 16px;
    font-weight: bold;
  }
  