
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main{
  max-width: 1280px;
  margin: auto;
  min-height: 90vh;
}